<template>
  <div>
    <div class="header">
      <div class="cpt_home_header_logo">
        <img
          class="logo-img"
          style="width: 32px; height: 32px"
          src="@/assets/home/MSpaceLogo1.png"
        />
        <span class="logo">天目将</span>
      </div>

      <div class="box">
        <div class="iconfont icon-zuixiaohua" @click="toMin" />
        <div class="iconfont" :class="isFull ? 'icon-huanyuan' : 'icon-zuidahua'" @click="toMax" />
        <div class="iconfont icon-guanbi1" @click="toClose" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFull: false
    };
  },
  methods: {
    toMin() {
      console.log('toMin');
      const electron = window.electron;
      const ipcRenderer = electron?.ipcRenderer;
      ipcRenderer && ipcRenderer.send('min-app');
    },
    toMax() {
      console.log('toMax');
      const electron = window.electron;
      const ipcRenderer = electron?.ipcRenderer;
      this.isFull = !this.isFull;
      ipcRenderer && ipcRenderer.send('max-app', this.isFull);
    },
    toClose() {
      console.log('toClose');
      const electron = window.electron;
      const ipcRenderer = electron?.ipcRenderer;
      ipcRenderer && ipcRenderer.send('close-app');
    }
  }
};
</script>

<style lang="scss" scoped>
.header::v-deep {
  // position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 99999;
  height: 48px;
  background: #2c313b;
  -webkit-app-region: drag;
  user-select: none;
  text-align: right;
  .cpt_home_header_logo {
    display: flex;
    padding: 10px 0 0 10px;
    .logo {
      font-family: YouSheBiaoTiHei;
      font-size: 22px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-left: 10px;
    }
  }
  .box {
    float: right;
    display: flex;
  }
  .el-icon-plus {
    width: 48px;
    height: 48px;
    color: white;
    text-align: center;
    line-height: 48px;
    -webkit-app-region: no-drag;
  }
  .icon-zuixiaohua,
  .icon-zuidahua,
  .icon-guanbi1,
  .icon-huanyuan {
    width: 48px;
    height: 48px;
    font-size: 22px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    -webkit-app-region: no-drag;
  }
}
</style>
