<!--
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-09 10:35:38
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-11-09 17:08:17
-->

<template>
  <el-dropdown class="language-drown" @command="setLang">
    <span class="el-dropdown-link">{{$t('login.languageChange')}}<i class="el-icon-arrow-down el-icon--right"></i></span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="item in localeMap" :key="item.lang" :command="item.lang">
          {{ item.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
</el-dropdown>

</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: 'LocaleDropdown',
    data(){
      return {}
    },
    computed:{
      ...mapState('locale',['curLocale','localeMap'])
    },
    mounted(){},
    methods:{
      setLang(lang){
        console.log('lang:',lang);
        if (lang === this.curLocale) return
        // 需要重新加载页面让整个语言多初始化
        this.$store.dispatch('locale/changeLocale',lang)
        this.$i18n.locale = lang;
        // window.location.reload()
        // localeStore.setCurrentLocale({lang})
      }
    }
  }
</script>

<style lang="scss">
.language-drown{
  .el-dropdown-link {
    color: #409eff;
  }
}

.el-dropdown-menu{
  position: relative;
  overflow: unset;
  &::before{
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent  #2c313b transparent;
  }
  .el-dropdown-menu__item{
    padding: 8px 18px;
    &:first-child{
      border-radius: 4px 4px 0 0;
    }
    &:last-of-type{
      border-radius: 0 0 4px 4px;
    }

  }
}

</style>



